
:root {
  --blue-color: #2E3F50;
  --bg-color: #33475b;
  --text-color: #33475b;
  --lightblue-color: #00a4bd;
  --btn-bg-color: #eaf0f6;
  --orange-color: #e66e50;
  --btn-text-color: #506e91;
  --btn-border-color: #7c98b6;
}

body {
  font-family: "Lexend Deca", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #33475b;
  line-height: 24px;
}

.pdfContainer {
  margin-block: 2rem;
  display: block;
  margin-inline: 1rem;
}
.header {
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 1rem 0;
  border-bottom: 1px solid rgb(203, 214, 226);
}

ul.pdfList {
  list-style: none;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  padding: 0;
  gap: 1rem;
}

.btn {
  cursor: pointer;
  background-color: var(--btn-bg-color);
  padding: .64rem 1.12rem;
  border-radius: 3px;
  border-color: var(--btn-border-color);
  border-width: thin;
  border-style: solid;
  display: inline-flex;
  place-content: center;
  text-decoration: none;
  font-size: 12px;
  line-height: 14px;
  color: var(--btn-text-color);
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
  text-align: left;
}
.btn svg {
  width: 6px;
  height: 10px;
  display: inline-flex;
  line-height: 1;
  margin: auto 4px auto -2px;
  fill: var(--btn-text-color);
}

.download {
  color: #fff;
  border-color: var(--orange-color);
  background-color: var(--orange-color);
}
.downloadMap {
  color: var(--orange-color);
  border-color: var(--orange-color);
  background-color: transparent;
}

.goBack {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.goBack h3 {
  margin: 0;
}
#downloadContainer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem auto 0 auto;
  align-items: center;
  gap: 1rem;
}

.pdfContainer h2 {
  text-align: left;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-bottom: 16px;
  margin-top: 0;
  display: block;
  font-size: 22px;
  line-height: normal;
  margin-bottom: 0px;
}
.pdfContainer h3 {
  text-align: left;
}

table th, table td {
  text-align: left;
}